/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-06-09 09:32:59
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:33:06
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    curretMenuIndex:'',
  },
  mutations: {
    setCurretMenuIndex(state,value){
      state.curretMenuIndex = value
    }
  },
  actions: {
  },
  modules: {
  }
})
