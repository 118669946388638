/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-24 10:16:41
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:59:43
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signIn',
    name:"signIn",
    component: resolve => require(['../pages/SignIn/signIn'], resolve),
  },   
  {
    path: '/',
    name:"store",
    component: resolve => require(['../pages/HomePage/store'], resolve),
    children:[
      {
        path: '/',
        name:'sweepstakesSetup',
        component: resolve => require(['../pages/LotteryManagement/sweepstakesSetup'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/sweepstakesSetup',
        name:'sweepstakesSetup',
        component: resolve => require(['../pages/LotteryManagement/sweepstakesSetup'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/eventDetails',
        name:'eventDetails',
        component: resolve => require(['../pages/LotteryManagement/eventDetails'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      {
        path: '/luckyList',
        name:'luckyList',
        component: resolve => require(['../pages/LotteryManagement/luckyList'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
      

      {
        path: '/votingActivity',
        name:'votingActivity',
        component: resolve => require(['../pages/VotingManagement/votingActivity'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },{
        path: '/singleChoice',
        name:'singleChoice',
        component: resolve => require(['../pages/VotingManagement/singleChoice'], resolve),
        meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
      },
    ]    
  },
  {
    path: '/luckyDraw',
    name:'luckyDraw',
    component: resolve => require(['../pages/LotteryManagement/luckyDraw'], resolve),
    meta:{ requireAuth:true,roleId:0 ,keepAlive: false}
  }
  // {
  //   path: '/',
  //   name:"home",
  //   component: resolve => require(['../pages/Home/index'], resolve),
  // },
  // {
  //   path: '/info',
  //   name:"info",
  //   component: resolve => require(['../pages/Home/info'], resolve),
  // },
  // {
  //   path: '/draw',
  //   name:"draw",
  //   component: resolve => require(['../pages/Home/luckyDraw'], resolve),
  // },
  // {
  //   path: '/list',
  //   name:"list",
  //   component: resolve => require(['../pages/Home/luckyList'], resolve),
  // },
]

const router = new VueRouter({
  routes
})

// // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/signIn')) {
      window.localStorage.removeItem('token')
      next()
  } else {
      let user = window.localStorage.getItem('token');
      if (!user && to.path !== '/signIn') {
          next({
              path: '/signIn'
          })
      } else {
          next()
      }
  }
});

export default router
