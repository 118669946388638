/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-06-09 09:17:34
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:26:05
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-06-09 09:17:34
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:20:43
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:04:28
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:17:43
 */
var storeMenu = [
    {
        icon:"el-icon-s-home",
        index:"",
        sbuMenu:true,
        title:"抽奖管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"sweepstakesSetup",
                sbuMenu:true,
                subs:null,
                title:"抽奖设置",
                type:0,
            },
        ],
    },
    {
        icon:"el-icon-s-custom",
        index:"VotingManagement",
        sbuMenu:true,
        title:"投票管理",
        type:0,
        subs:[
            {
                icon:"",
                index:"votingActivity",
                sbuMenu:true,
                subs:null,
                title:"投票活动",
                type:0,
            }
        ],
    }
]

var menuJson = {
    storeMenu
}
export default menuJson